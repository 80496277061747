<template>
  <CHeader position="sticky" class="header mb-4">
    <CContainer fluid>
      <CHeaderToggler
        :class="[
          'px-md-0',
          'me-md-3',
          'd-lg-none',
          { 'prevent-hide': !sidebarVisible },
        ]"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none" to="/">
        <CIcon :icon="logoNegative" height="34" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-sm-flex ms-auto me-3">
        <!--        <AppHeaderDropdownNotif />-->
      </CHeaderNav>
      <CHeaderNav class="ms-auto me-3">
        <AppHeaderDropdownTasks />
        <AppHeaderDropdownMssgs />
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
      <CHeaderNav>
        <CButtonGroup aria-label="Theme switch" size="sm">
          <CFormCheck
            id="btn-light-theme"
            type="radio"
            :button="{}"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            id="btn-dark-theme"
            type="radio"
            :button="{}"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <!--      <CHeaderToggler class="px-md-0 me-md-3">-->
      <!--        <CIcon-->
      <!--          icon="cil-applications-settings"-->
      <!--          size="lg"-->
      <!--          @click="$store.commit('toggleAside')"-->
      <!--        />-->
      <!--      </CHeaderToggler>-->
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
// import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logoNegative } from '@/assets/brand/logo-negative'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    // AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  created() {
    this.initEventStream()
  },
  methods: {
    initEventStream() {
      let domain = process.env.VUE_APP_API_URL
      let port = ':8800'
      const domainParts = domain.split(':')
      domain = domainParts[0] + ':' + domainParts[1]

      if (domain.endsWith('.pl')) {
        // On remote servers we need to communicate over HTTPS to avoid security issues.
        // It is also important to use standard port to avoid SSL errors.
        domain = domain.replace(/api\./, 'sse.')
        port = ''
      }

      document.cookie =
        'mercureAuthorization=' +
        localStorage.mercureToken +
        '; expires=0; path=/; domain=' +
        domain
          .replace('sse', '')
          .replace('https://', '')
          .replace('http://', '') +
        port

      const url = new URL(`${domain}${port}/.well-known/mercure`)
      url.searchParams.append('topic', localStorage.mercureTopic)

      const source = new EventSource(url.toString(), {
        withCredentials: true,
      })
      source.onmessage = (event) => {
        const data = JSON.parse(event.data).data
        this.emitter.emit(data.type, data)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  background: var(--cui-primary);
}
</style>
