export function toDoTask(type, context) {
  const types = {
    order_canceled: {
      title: 'Anulowane zlecenie',
      description: 'dowiedz się, co było powodem anulowania zlecenia',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_price_accepted: {
      title: 'Przyjęto wycenę',
      description: 'przekaż zamówienie do akceptacji partnera lub do logistyki',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_price_rejected: {
      title: 'Odrzucono wycenę',
      description:
        'należy dowiedzieć się dlaczego klient odrzucił wycenę i rozwiązać sytuację',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_client_updated_data: {
      title: 'Uzupełniono dane',
      description: 'klient uzupełnił dane. Zapoznaj się z nowymi informacjami',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_partner_priced: {
      title: 'Wyceniono odpad',
      description:
        'sprawdź otrzymane wyceny i gdy jest możliwość to złóż wycenę klientowi',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_completed: {
      title: 'Zakończono zlecenie',
      description:
        'zadanie informacyjne - sprawdź zamówienie lub oznacz zadanie jako ukończone',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_prepaid: {
      title: 'Opłacono przedpłatę',
      description: 'klient opłacił przedpłatę - przekaż zamówienie do partnera',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_processing: {
      title: 'Realizacja zlecenia',
      description:
        'zadanie informacyjne - sprawdź zamówienie lub oznacz zadanie jako ukończone',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_processing_reminder: {
      title: 'Oczekiwanie na realizację',
      description:
        'partner nie przyjął zamówienia do realizacji. Skontaktuj się z partnerem',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_complete_reminder: {
      title: 'Oczekiwanie na wykonanie',
      description:
        'na dzień jutrzejszy partner określił datę odbioru. Skontaktuj się z nim w celu potwierdzenia terminu realizacji',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_complete_settled: {
      title: 'Zamówienie do rozliczenia',
      description: 'wskazane zamówienie należy rozliczyć',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    record_created: {
      title: 'Zapytanie o ewidencję',
      description: 'zapytanie o ewidencję',
      autoFinish: false,
      url: () => this.$router.push(`/records`),
    },
    order_prepayment: {
      title: 'Przedpłata',
      description:
        'zadanie informacyjne - Klient zaakceptował wycenę z przedpłatą. Należy wystawić fakturę',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_lead_reminder: {
      title: 'Niewypełniony formularz',
      description:
        'zadanie informacyjne - Klient nie wypełnił przesłanego formularza. Skontaktuj się z nim',
      autoFinish: false,
      placeholder: 'order_lead',
      url: () => this.$router.push(`/sent-forms`),
    },
    offer_want_buy_created: {
      title: 'Potencjalny partner',
      description:
        'nowy partner chce kupować z nami odpady, skontaktuj się z nim',
      autoFinish: false,
      url: () => this.$router.push(`/joins`),
    },
    offer_join_created: {
      title: 'Nowy partner',
      description:
        'nowy partner chce dołączyć do mamodpad, skontaktuj się z nimi',
      autoFinish: false,
      url: () => this.$router.push(`/joins`),
    },
    order_created: {
      title: 'Nowe zamówienie',
      description:
        'pojawiło się nowe zamówienie, które zostało przypisane do Ciebie',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    pending_partner_pricing_reminder: {
      title: 'Partner nie wycenił zamówienia',
      description: 'partner nie wycenił zamówienia w ciągu 2 godzin',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    pending_potentially_partner_pricing_reminder: {
      title: 'Partnerzy nie wycenili zamówienia',
      description:
        'żaden z partnerów nie wycenił tego zamówienia w ciągu 3 godzin. Skontaktuj się z nimi i uzupełnij ewentualnie wycenę telefonicznie',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    sale_offer_created: {
      title: 'Nowa oferta',
      description:
        'pojawiła się nowa oferta, która została przypisana do Ciebie',
      autoFinish: false,
      placeholder: 'sale_offer',
      url: (id) => this.$router.push(`/sale-offers/${id}`),
    },
    price_proposal_reminder: {
      title: 'Klient nie odpowiedział na wycenę',
      description:
        'skontaktuj się z klientem który nie odpowiedział na naszą wycenę odpadu',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    update_request_reminder: {
      title: 'Klient nie uzupełnił danych',
      description: 'skontaktuj się z nim aby mu o tym przypomnieć',
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
    order_reminder: {
      title: context.title,
      description: context.description,
      autoFinish: false,
      placeholder: 'order',
      url: (id) => this.$router.push(`/orders/${id}`),
    },
  }

  return types[type]
}
