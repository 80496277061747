<template>
  <router-view />
  <div class="toasts">
    <CToast
      :color="toast.color"
      visible
      v-for="(toast, index) in toasts"
      :key="index"
    >
      <div class="d-flex">
        <CToastBody>{{ toast.message }}</CToastBody>
        <CToastClose class="me-2 m-auto" white />
      </div>
    </CToast>
  </div>
</template>
<script>
import store from '@/store'
import { watch } from 'vue'
export default {
  computed: {
    toasts() {
      return store.state.toasts
    },
  },
  setup() {
    store.dispatch('fetchCategories')
    store.dispatch('fetchSupervisors')
    if (localStorage.getItem('theme')) {
      store.commit({
        type: 'toggleTheme',
        value: localStorage.getItem('theme'),
      })
    }

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

.toasts {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  margin: 1rem;
}
</style>
