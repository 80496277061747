<template>
  <CRow>
    <CCol md="9"
      ><strong>{{ toDoTask(toDo.type, toDo.context).title }}</strong>
      <span class="id" v-if="toDoTask(toDo.type, toDo.context).placeholder"
        >({{
          toDo.context[toDoTask(toDo.type, toDo.context).placeholder]
        }})</span
      ></CCol
    >
    <CCol
      ><CButton
        size="sm"
        color="secondary"
        @click.stop="
          () => {
            toDoTask(toDo.type, toDo.context).autoFinish
              ? finish(toDo).then(() => {
                  toDoTask(toDo.type, toDo.context).placeholder
                    ? toDoTask(toDo.type, toDo.context).url(
                        toDo.context[
                          toDoTask(toDo.type, toDo.context).placeholder
                        ],
                      )
                    : toDoTask(toDo.type, toDo.context).url()
                })
              : toDoTask(toDo.type, toDo.context).placeholder
                ? toDoTask(toDo.type, toDo.context).url(
                    toDo.context[toDoTask(toDo.type, toDo.context).placeholder],
                  )
                : toDoTask(toDo.type, toDo.context).url()
          }
        "
      >
        przejdź
      </CButton></CCol
    >
  </CRow>
  <CRow>
    <CCol class="description">{{
      toDoTask(toDo.type, toDo.context).description
    }}</CCol>
  </CRow>
</template>

<script>
import api from '@/services/api'
import { toDoTask } from '@/mixins/ToDoTask'

export default {
  name: 'ToDoTask',
  props: ['toDo'],
  created() {},
  methods: {
    toDoTask,
    finish(item) {
      return api.put('/api/todos/' + item.id + '/finish').then(() => {
        this.$emit('finished-task')
      })
    },
  },
}
</script>

<style scoped lang="scss">
.id {
  margin-left: 0.25rem;
}
.description {
  text-wrap: initial;
}
</style>
