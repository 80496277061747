<template>
  <CDropdown variant="outline" auto-close="outside">
    <CDropdownToggle
      placement="bottom-end"
      class="py-0 position-relative"
      :caret="false"
    >
      <CIcon icon="cil-bell" size="lg" />
      <CBadge
        color="danger"
        position="top-start"
        shape="rounded-pill"
        v-if="todosTotal > 0"
      >
        {{ todosTotal }}
        <span class="visually-hidden">niewykonane zadania</span>
      </CBadge>
    </CDropdownToggle>
    <CDropdownMenu class="py-0 menu">
      <CDropdownHeader
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10"
      >
        <strong>Posiadasz {{ todosTotal }} niewykonanych zadań</strong>
      </CDropdownHeader>
      <CDropdownItem class="d-block" v-for="toDo in toDos" v-bind:key="toDo.id">
        <CRow>
          <CCol md="10">
            <ToDoTask :toDo="toDo" @finished-task="loadToDos" />
          </CCol>
          <CCol>
            <CFormSwitch
              :disabled="toDo.finished"
              v-model="toDo.finished"
              @change.stop="finish(toDo, $event)"
              color="success"
            ></CFormSwitch>
          </CCol>
        </CRow>
      </CDropdownItem>
      <div class="p-2">
        <CButton
          color="primary"
          variant="outline"
          class="w-100"
          @click="$router.push('/to-dos')"
        >
          Zobacz wszystkie zadania
        </CButton>
      </div>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
import api from '@/services/api'
import store from '@/store'
import ToDoTask from '@/components/ToDoTask.vue'
import { toDoTask } from '@/mixins/ToDoTask'

export default {
  name: 'TheHeaderDropdownTasks',
  components: { ToDoTask },
  data() {
    return {
      toDos: [],
      todosTotal: 0,
    }
  },
  methods: {
    loadToDos() {
      let uri = `?limit=5&offset=0&finished=false`

      api
        .get(`/api/todos${uri}`)
        .then((response) => {
          this.toDos = response.data.items
          this.todosTotal = response.data.total
        })
        .catch((error) => {
          console.error(error)
        })
    },
    finish(item) {
      api.put('/api/todos/' + item.id + '/finish').then(() => {
        store.commit('showSuccessToast', {
          message: 'Zadanie zostało oznaczone jako wykonane.',
          sound: false,
        })
        this.loadToDos()
      })
    },
  },
  created() {
    this.loadToDos()

    this.emitter.on('toDo', (e) => {
      this.loadToDos()

      store.commit('showWarningToast', {
        message: toDoTask(e.todo).title,
        sound: true,
      })
    })
  },
}
</script>

<style scoped lang="scss">
.menu {
  width: 600px;
}
</style>
