import { createStore } from 'vuex'
import { UserModule } from '@/store/modules/User'
import api from '@/services/api'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    toasts: [],
    appName: process.env.VUE_APP_NAME,
    categories: [],
    supervisors: [],
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
    getSupervisors(state) {
      return state.supervisors
    },
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
      localStorage.setItem('theme', payload.value)
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    showErrorToast(state, payload) {
      let toast = { message: null, color: null }
      toast.message = payload.message
      toast.color = 'danger'
      state.toasts.push(toast)
      if (
        payload.sound &&
        localStorage.getItem('notificationSound') !== 'false'
      ) {
        let audio = new Audio(require('/public/notification.mp3'))
        audio.play()
      }
    },
    showWarningToast(state, payload) {
      let toast = { message: null, color: null }
      toast.message = payload.message
      toast.color = 'warning'
      state.toasts.push(toast)
      if (
        payload.sound &&
        localStorage.getItem('notificationSound') !== 'false'
      ) {
        let audio = new Audio(require('/public/notification.mp3'))
        audio.play()
      }
    },
    showSuccessToast(state, payload) {
      let toast = { message: null, color: null }
      toast.message = payload.message
      toast.color = 'success'
      state.toasts.push(toast)
      if (
        payload.sound &&
        localStorage.getItem('notificationSound') !== 'false'
      ) {
        let audio = new Audio(require('/public/notification.mp3'))
        audio.play()
      }
    },
    updateCategories(state, categories) {
      state.categories = categories
    },
    updateSupervisors(state, supervisors) {
      state.supervisors = supervisors
    },
  },
  actions: {
    fetchCategories({ commit }) {
      if (
        localStorage.getItem('categories') &&
        localStorage.getItem('categories-timestamp') > new Date().getTime()
      ) {
        commit(
          'updateCategories',
          JSON.parse(localStorage.getItem('categories')),
        )
        return
      }

      let categories = []
      api
        .get('/api/categories?level=0&type=business')
        .then((response) => {
          const data = response.data
          data.categories.forEach((category) => {
            categories.push(category)
          })

          localStorage.setItem('categories', JSON.stringify(categories))
          let date = new Date()
          date.setDate(new Date().getDate() + 7)
          localStorage.setItem('categories-timestamp', date.getTime())
          commit('updateCategories', categories)
        })
        .catch((e) => {
          console.error(e)

          commit('updateCategories', [])
        })
    },
    async fetchSupervisors({ commit }) {
      if (
        localStorage.getItem('supervisors') &&
        localStorage.getItem('supervisors-timestamp') > new Date().getTime()
      ) {
        commit(
          'updateSupervisors',
          JSON.parse(localStorage.getItem('supervisors')),
        )
        return
      }
      let supervisors = []
      api
        .get('/api/users?role=ROLE_SUPERVISOR')
        .then((response) => {
          response.data.items.forEach((supervisor) => {
            supervisors.push(supervisor)
          })
          localStorage.setItem('supervisors', JSON.stringify(supervisors))
          let date = new Date()
          date.setDate(new Date().getDate() + 7)
          localStorage.setItem('supervisors-timestamp', date.getTime())
          commit('updateSupervisors', supervisors)
        })
        .catch((e) => {
          console.error(e)

          commit('updateSupervisors', [])
        })
    },
  },
  modules: {
    user: new UserModule(),
  },
})
