<template>
  <CSidebar
    class="bg-dark-gradient"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="d-none d-md-flex">
      <img src="logo.webp" alt="logo" :width="sidebarUnfoldable ? 40 : 70" />
      <CSidebarToggler @click="$store.commit('toggleUnfoldable')" />
    </CSidebarBrand>
    <AppSidebarNav :stats="stats" :key="statsKey" />
  </CSidebar>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import api from '@/services/api'
import store from '@/store'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    const supervisor = ref(null)
    const stats = ref({})
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      supervisor,
      stats,
      statsKey: 0,
    }
  },
  mounted() {
    this.companyId = store.state.user.identity.companyId
    this.loadCompanyData()
    this.loadStats()
    setInterval(() => {
      this.loadStats()
    }, 5000)
  },
  methods: {
    loadCompanyData() {
      api.get(`/api/companies/${this.companyId}`).then((response) => {
        if (response.data.supervisor) {
          this.supervisor = response.data.supervisor
        }
      })
    },
    loadStats() {
      // api.get(`/api/partner/statistics/count`).then((res) => {
      //   this.stats = res.data
      //   this.statsKey = Math.random()
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.supervisor {
  &-header {
    font-size: 1rem;
    font-weight: bold;
  }
  &-info {
    font-size: 0.75rem;
  }
}
</style>
