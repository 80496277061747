export default [
  // {
  //   component: "CNavItem",
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: "cil-speedometer",
  //   badge: {
  //     color: "info",
  //     text: "NEW",
  //   },
  // },
  {
    component: 'CNavItem',
    name: 'Zadania',
    to: '/to-dos',
    icon: 'cil-bell',
  },
  {
    component: 'CNavItem',
    name: 'Oferty z pozostałych serwisów',
    to: '/sale-offers',
    icon: 'cil-list',
    meta: {
      roles: ['ROLE_ADMIN', 'ROLE_TRADER', 'ROLE_MOBILE_TRADER'],
    },
  },
  {
    component: 'CNavItem',
    name: 'Mamodpad',
    icon: 'cil-arrow-right',
    meta: {
      roles: ['ROLE_ADMIN', 'ROLE_TRADER', 'ROLE_MOBILE_TRADER'],
    },
    items: [
      {
        component: 'CNavItem',
        name: 'Zamówienia',
        to: '/orders',
        icon: 'cil-list',
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_TRADER', 'ROLE_MOBILE_TRADER'],
        },
        badge: {
          color: 'success',
          type: 'orders_not_completed',
        },
      },
      {
        component: 'CNavItem',
        name: 'Wysłane formularze',
        to: '/sent-forms',
        icon: 'cil-dollar',
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_TRADER', 'ROLE_MOBILE_TRADER'],
        },
      },
      {
        component: 'CNavItem',
        name: 'Usługi',
        to: '/services',
        icon: 'cil-star',
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_TRADER', 'ROLE_MOBILE_TRADER'],
        },
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Ewidencja',
    to: '/records',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Potencjalni partnerzy',
    to: '/joins',
    icon: 'cil-user-plus',
  },
  {
    component: 'CNavItem',
    name: 'Firmy',
    to: '/companies',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'Moje firmy',
    to: '/my-companies',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'Użytkownicy',
    to: '/users',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Ustawienia',
    to: '/settings',
    icon: 'cil-settings',
  },
  // {
  //   component: "CNavTitle",
  //   name: "Theme",
  // },
  // {
  //   component: "CNavItem",
  //   name: "Colors",
  //   to: "/theme/colors",
  //   icon: "cil-drop",
  // },
  // {
  //   component: "CNavItem",
  //   name: "Typography",
  //   to: "/theme/typography",
  //   icon: "cil-pencil",
  // },
  // {
  //   component: "CNavTitle",
  //   name: "Components",
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Base",
  //   to: "/base",
  //   icon: "cil-puzzle",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Accordion",
  //       to: "/base/accordion",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Breadcrumbs",
  //       to: "/base/breadcrumbs",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Cards",
  //       to: "/base/cards",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Carousels",
  //       to: "/base/carousels",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Collapses",
  //       to: "/base/collapses",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "List Groups",
  //       to: "/base/list-groups",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Navs & Tabs",
  //       to: "/base/navs",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Paginations",
  //       to: "/base/paginations",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Placeholders",
  //       to: "/base/placeholders",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Popovers",
  //       to: "/base/popovers",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Progress",
  //       to: "/base/progress",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Spinners",
  //       to: "/base/spinners",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Tables",
  //       to: "/base/tables",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Tooltips",
  //       to: "/base/tooltips",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Buttons",
  //   to: "/buttons",
  //   icon: "cil-cursor",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Buttons",
  //       to: "/buttons/standard-buttons",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Button Groups",
  //       to: "/buttons/button-groups",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Dropdowns",
  //       to: "/buttons/dropdowns",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Forms",
  //   to: "/forms",
  //   icon: "cil-notes",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Form Control",
  //       to: "/forms/form-control",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Select",
  //       to: "/forms/select",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Multi Select",
  //       to: "/forms/multi-select",
  //       badge: {
  //         color: "danger",
  //         text: "PRO",
  //       },
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Checks & Radios",
  //       to: "/forms/checks-radios",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Range",
  //       to: "/forms/range",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Input Group",
  //       to: "/forms/input-group",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Floating Labels",
  //       to: "/forms/floating-labels",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Date Picker",
  //       to: "/forms/date-picker",
  //       badge: {
  //         color: "danger",
  //         text: "PRO",
  //       },
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Date Range Picker",
  //       to: "/forms/date-range-picker",
  //       badge: {
  //         color: "danger",
  //         text: "PRO",
  //       },
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Time Picker",
  //       to: "/forms/time-picker",
  //       badge: {
  //         color: "danger",
  //         text: "PRO",
  //       },
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Layout",
  //       to: "/forms/layout",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Validation",
  //       to: "/forms/validation",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Icons",
  //   to: "/icons",
  //   icon: "cil-star",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "CoreUI Icons",
  //       to: "/icons/coreui-icons",
  //       badge: {
  //         color: "info",
  //         text: "NEW",
  //       },
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Brands",
  //       to: "/icons/brands",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Flags",
  //       to: "/icons/flags",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Notifications",
  //   to: "/notifications",
  //   icon: "cil-bell",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Alerts",
  //       to: "/notifications/alerts",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Badges",
  //       to: "/notifications/badges",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Modals",
  //       to: "/notifications/modals",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavItem",
  //   name: "Widgets",
  //   to: "/widgets",
  //   icon: "cil-calculator",
  //   badge: {
  //     color: "info",
  //     text: "NEW",
  //   },
  // },
  // {
  //   component: "CNavItem",
  //   name: "Smart Table",
  //   to: "/smart-table",
  //   icon: "cil-grid",
  //   badge: {
  //     color: "danger",
  //     text: "PRO",
  //   },
  // },
  // {
  //   component: "CNavTitle",
  //   name: "Plugins",
  // },
  // {
  //   component: "CNavItem",
  //   name: "Calendar",
  //   to: "/calendar",
  //   icon: "cil-calendar",
  //   badge: {
  //     color: "danger",
  //     text: "PRO",
  //   },
  // },
  // {
  //   component: "CNavItem",
  //   name: "Charts",
  //   to: "/charts",
  //   icon: "cil-chart-pie",
  // },
  // {
  //   component: "CNavTitle",
  //   name: "Extras",
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Pages",
  //   to: "/pages",
  //   icon: "cil-star",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Login",
  //       to: "/pages/login",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Register",
  //       to: "/pages/register",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Error 404",
  //       to: "/pages/404",
  //     },
  //     {
  //       component: "CNavItem",
  //       name: "Error 500",
  //       to: "/pages/500",
  //     },
  //   ],
  // },
  // {
  //   component: "CNavGroup",
  //   name: "Apps",
  //   to: "/apps",
  //   icon: "cil-layers",
  //   items: [
  //     {
  //       component: "CNavGroup",
  //       name: "Invoicing",
  //       to: "/apps/invoicing",
  //       icon: "cil-spreadsheet",
  //       items: [
  //         {
  //           component: "CNavItem",
  //           name: "Invoice",
  //           to: "/apps/invoicing/invoice",
  //           badge: {
  //             color: "danger",
  //             text: "PRO",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       component: "CNavGroup",
  //       name: "Email",
  //       to: "/apps/email",
  //       icon: "cil-envelope-closed",
  //       items: [
  //         {
  //           component: "CNavItem",
  //           name: "Inbox",
  //           to: "/apps/email/inbox",
  //           icon: "cil-envelope-closed",
  //           badge: {
  //             color: "danger",
  //             text: "PRO",
  //           },
  //         },
  //         {
  //           component: "CNavItem",
  //           name: "Message",
  //           to: "/apps/email/message",
  //           icon: "cil-envelope-open",
  //           badge: {
  //             color: "danger",
  //             text: "PRO",
  //           },
  //         },
  //         {
  //           component: "CNavItem",
  //           name: "Compose",
  //           to: "/apps/email/compose",
  //           icon: "cil-envelope-letter",
  //           badge: {
  //             color: "danger",
  //             text: "PRO",
  //           },
  //         },
  //       ],
  //     },
  //   ],
  // },
]
