<template>
  <CDropdown variant="outline" alignment="end" auto-close="outside">
    <CDropdownToggle
      placement="bottom-end"
      class="py-0 position-relative"
      :caret="false"
    >
      <CIcon icon="cil-chat-bubble" size="lg" />
      <CBadge
        color="warning"
        position="top-start"
        shape="rounded-pill"
        v-if="chatsTotal > 0"
      >
        {{ chatsTotal }}
        <span class="visually-hidden">nieprzeczytanych czatów</span>
      </CBadge>
    </CDropdownToggle>
    <CDropdownMenu class="py-0 menu">
      <CDropdownHeader
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10"
      >
        <strong>Posiadasz {{ chatsTotal }} nieprzeczytanych czatów</strong>
      </CDropdownHeader>
      <CDropdownItem
        class="d-block pointer"
        v-for="chat in chats"
        v-bind:key="chat.id"
        @click="
          () => {
            markAsRead(chat.id).then(() => {
              this.loadUnreadChats(false)
              this.$router
                .push({
                  name: 'Szczegóły zamówienia',
                  params: { id: chat.order.id },
                  query: { chatId: chat.id },
                })
                .catch(() => {})
            })
          }
        "
      >
        <CRow>
          <CCol>
            <strong>Zamówienie: {{ chat.order.id }}</strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            Czat {{ getType(chat.type) }}
            <span v-if="chat.type === 'PARTNER'"
              >({{ chat.participant }})</span
            ></CCol
          >
        </CRow>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
import api from '@/services/api'
import store from '@/store'
export default {
  name: 'TheHeaderDropdownMssgs',
  components: {},
  setup() {},
  data() {
    return {
      chats: [],
      chatsTotal: 0,
    }
  },
  methods: {
    loadUnreadChats() {
      let uri = `?limit=5&offset=0`
      api.get(`/api/order-chats/unread${uri}`).then((response) => {
        this.chats = response.data.items
        this.chatsTotal = response.data.total
      })
    },
    async markAsRead(chat) {
      return api.put(`/api/order-chats/${chat}/read`)
    },
    getType(type) {
      switch (type) {
        case 'CLIENT':
          return 'z klientem'
        case 'PARTNER':
          return 'z partnerem'
        default:
          return 'pomiędzy klientem a partnerem'
      }
    },
  },
  created() {
    this.loadUnreadChats()
    this.emitter.on('chat', () => {
      this.loadUnreadChats()

      store.commit('showWarningToast', {
        message: 'Nowa wiadomość na czacie',
        sound: true,
      })
    })
  },
}
</script>
